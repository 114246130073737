import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Menu, Segment, Visibility } from "semantic-ui-react";
import { Media, MENU_ITEMS } from "./index";

export default class DesktopContainer extends Component {
  state = { active: this.getPageName() };

  getPageName() {
    switch (window.location.pathname) {
      case "/":
        return "Home";
      case "/Home":
        return "Home";
      case "/commercials":
        return "Commercials";
      case "/long-lets":
        return "Long Lets";
      case "/short-lets":
        return "Short Lets";
      case "/flatmate":
        return "Find Flat Mate";
      case "/room":
        return "Rooms";
      case "/residential-sales":
        return "Residential Sales";
      case "/commercial-sales":
        return "Commercial Sales";
      case "/career":
        return "Careers";
      case "/contact":
        return "Contact Us";
      case "/company":
        return "Company";
      default:
        return "Home";
    }
  }

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.active !== this.state.active) {
  //    this.getPageName()
  //   }
  // }

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            //inverted
            textAlign="center"
            vertical
            style={{ padding: 0 }}
          >
            <Menu
              fixed={fixed ? "top" : null}
              //inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container style={{ width: 'auto' }}>
                <Menu.Item>
                  <Link to="/">
                    <Image
                      alt="header-logo"
                      src="/img/logo-green.png"
                      size="small"
                    />
                  </Link>
                </Menu.Item>

                <Menu.Menu position="right">
                  {MENU_ITEMS.map((item, i) => (
                    <Link
                      to={item.url}
                      style={{ display: "flex" }}
                      key={"desktop-menu-item-" + i}
                    >
                      <Menu.Item
                        name={item.name}
                        active={this.state.active === item.name}
                        onClick={(e, data) => {
                          document
                            .getElementsByClassName("link active item")?.[0]
                            ?.classList?.remove("active");
                          this.setState({ active: data.name });
                        }}
                        link
                        id={"desktop-menu-item-" + item.url.substring(1)}
                        style={{
                          alignSelf: "center",
                          backgroundColor: item.name === "Add My Properties" ? "#21BA45" : null,
                          borderRadius: item.name === "Add My Properties" ? null : null,
                          fontWeight: item.name === "Add My Properties" ? "700" : null,
                          color: item.name === "Add My Properties" ? "white" : null,
                        }}
                      />
                    </Link>
                  ))}

                  {/* <Menu.Item as='a' active>Home</Menu.Item>
                  <Menu.Item as='a'>Work</Menu.Item>
                  <Menu.Item as='a'>Company</Menu.Item>
                  <Menu.Item as='a'>Careers</Menu.Item> */}
                </Menu.Menu>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Media >
    );
  }
}
