import caller from './Caller'

export const requestPropertyAdd = async (model) => {
  try {
    
    const { data } = await caller({
      method: 'POST',
      url: '/Properties/RequestPropertyAdd',
      data: model,
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  
    return data

  } catch (error) {
   return null; 
  }
}