import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import api from '../../api'
import { pageEnum } from '../../Common'
import { Loader, Icon } from 'semantic-ui-react'
import Marker from './components/Marker'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import { AwsUrl } from '../../helpers/aws'


const Maps = ({ filterForm, pageNumber, centerp }) => {

  const [data, setData] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(false)
  const [id, setId] = useState('')
  const [clickMarker, setClickMarker] = useState({ lat: 0, lng: 0 })
  const [loader, setLoader] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [center, setCenter] = useState({
    lat: 35.911292,
    lng: 14.489585
  })
  const [dragMap, setDragMap] = useState({
    lat: 0,
    lng: 0
  })

  const prevCenter = usePrevious(centerp)


  useEffect(() => {
    let layer;
    let internalMapContainer;
    if (mapLoaded) {
      internalMapContainer = document.querySelector(".gm-style");
      layer = document.createElement("div");
      layer.id = "infoboxlayer";
      internalMapContainer.append(layer);
    }
  }, [mapLoaded])

  useEffect(() => {

    searchByCoordinate()

  }, [filterForm])

  const searchByCoordinate = async () => {
    setSelectedMarker(null)
    setLoader(true)
    let res = {}
    if (typeof prevCenter === 'undefined') {
      setCenter({ lat: centerp.latitude, lng: centerp.longitude })
      res = await api.searchByCoordinate.searchCoordinate({ ...filterForm, latitude: centerp.latitude, longitude: centerp.longitude })
    } else {
      res = await api.searchByCoordinate.searchCoordinate({ ...filterForm, latitude: dragMap.lat, longitude: dragMap.lng })
    }

    if (res?.result) {
      // let centerMapData = res.result.filter(x => x.latitude != 0 && x.longitude != 0)
      // let averageLat = centerMapData.map(x => x.latitude).reduce((a, b) => a + b, 0) / centerMapData.length
      // let averageLng = centerMapData.map(x => x.longitude).reduce((a, b) => a + b, 0) / centerMapData.length
      setData(res.result)
      // setCenter({ lat: averageLat, lng: averageLng })
    }
    setLoader(false)
  }

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const searchByCoordinateDragEnd = async () => {
    setSelectedMarker(null)
    setLoader(true)

    const res = await api.searchByCoordinate.searchCoordinate({ ...filterForm, latitude: dragMap.lat, longitude: dragMap.lng })
    if (res?.result) {
      setData(res.result)
    }
    setLoader(false)
  }

  const _onChildClick = (key, childProps) => {
    setId(key)
    setSelectedMarker(true)
    setClickMarker({ lat: childProps.lat, lng: childProps.lng })

  }

  const onChange = (value) => {
    // setCenter({ lat: value.center.lat, lng: value.center.lng })
    setDragMap({ lat: value.center.lat, lng: value.center.lng })
  }

  function getPrice(long, short, commercional, lettingType) {
    let temp = 0
    if (lettingType == pageEnum.LongLets) {
      temp = long
    } else if (lettingType == pageEnum.CommercialLets) {
      temp = commercional
    } else {
      temp = short
    }
    return temp
  }

  let screenWidth = window.innerWidth * 0.8
  let leftPopup = window.innerWidth * 0.46

  const MobilView = (id) => {
    // const item = this.state.data?.find(x => x.id == id) 
    const items = data.filter(x => x.latitude == clickMarker.lat && x.longitude == clickMarker.lng)
    let item = items[items.length - 1]
    return (
      <Link target="_blank" to={'/detail/' + item.id+ '/' + item.lettingType}
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: "absolute",
          backgroundColor: 'white',
          top: '130px',
          left: `-${leftPopup}px`,
          width: screenWidth,
          borderRadius: 20,
          height: 100,
          zIndex: 999
        }}>
        <div style={{ width: '100px', height: '100px' }}>
          <img className="rcorners1"
            // src={"https://easyrent.com.mt" + item.photos[0].thumbnail} width="100" height="100"></img>
            src={AwsUrl + item.thumbnailImageUrl} width="100" height="100"></img>
        </div>
        <div style={{ display: 'flex', marginLeft: 10, alignItems: "center" }}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Ref: {item?.id}</span><br />
            <span>{item?.typeName}</span><br />
            <span>{item?.title}</span><br />
            <span>{item.locationName}</span><br />
            <a style={{ color: '#000', fontSize: "small" }}><Icon name='euro' />
              {item.price}</a>
          </p>
        </div>
      </Link>
    )
  }

  return (
    <div>
      {document.getElementsByClassName('gm-style-iw-d')[0]?.remove()}
      {document.getElementsByClassName('gm-style-iw gm-style-iw-c')[0]?.remove()}
      {document.getElementsByClassName('gm-style-iw-t')[0]?.remove()}
      <div style={{ height: '500px', width: '100%' }}>
        {loader ?
          <div style={{
            position: "absolute", width: "100%", height: '500px',
            display: "flex", justifyContent: "center", alignItems: "center"
          }}>
            <Loader active inline /></div> :
          null}
        <GoogleMapReact
          onClick={e => {
            if (e.lat !== clickMarker.lat || e.lng !== clickMarker.lng) {
              setSelectedMarker(null)
            }
          }}
          onDragEnd={() => searchByCoordinateDragEnd()}
          bootstrapURLKeys={{ key: 'AIzaSyCny9n4aoP6a6csRPxw-CsZq3ly5hJhBZU' }}
          defaultCenter={center}
          defaultZoom={14}
          center={center}
          onChange={onChange}
          onChildClick={_onChildClick}
          onGoogleApiLoaded={() => setMapLoaded(true)}
        >
          {
            data?.map(item => (
              <Marker
                item={item}
                key={item.id}
                lat={item.latitude}
                lng={item.longitude}
                name={getPrice(item.longLetsPrice, item.shortLetsPrice, item.commercialLetsPrice, item.lettingType)}
              />
            ))
          }
          {isMobile && selectedMarker &&
            MobilView(id)
          }

        </GoogleMapReact>
      </div>
    </div >
  )
}

export default Maps