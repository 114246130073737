import caller from './Caller'

export const searchCoordinate = async (model) => { 
  const { data } = await caller({
    method: 'post',
    url: '/Properties/SearchByCoordinate',
    data: {
      ...model
    }
  })
  return data
}