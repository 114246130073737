export const pageEnum = {
    LongLets: 1,
    ShortLets: 2,
    CommercialLets: 3,
    Room: 5,
    ResidentialSale: 6,
    CommercialSale: 7
}

export const sortingTypeEum = {
    Default: 0,
    PriceLowToHigh: 1,
    PriceHighToLow: 2,
    Popular: 3
}