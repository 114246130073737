import caller from './Caller'

export const requestFlatMate = async (model) => {
  const { data } = await caller({
    method: 'POST',
    url: '/Form/FlatMateRequest',
    data: {
      ...model
    }
  })

  return data
}