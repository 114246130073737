import caller from './Caller'

export const requestViewing = async (model) => {
  const { data } = await caller({
    method: 'POST',
    url: '/Form/RequestViewing',
    data: {
      ...model
    }
  })

  return data
}