import React from 'react'
import { Modal } from 'semantic-ui-react' 
import ReactPlayer from 'react-player'

const VideoModal = ({ isOpen, onClose, url }) => {

  //https://www.youtube.com/watch?v=AJTyWlFDiv8

  function getUrl(urls) {

    let returnUrl = ""
    const splitUrl = urls?.substring(0, 5)
    if (splitUrl == 'https') {
      returnUrl = urls?.substring(32)
    } else if (splitUrl == 'www') {
      returnUrl = urls?.substring(28)
    } else {
      returnUrl = ''
    }
    return returnUrl;
  }

  return (
    <Modal
      style={{ height: '400px' }}
      // style={{ width: "80%", height: '500px' }}
      size="small"
      closeIcon
      open={isOpen}
      onClose={() => onClose()}

    >
      <div>

        <ReactPlayer
          url={url}
          width='100%'
          height='400px'
          controls={true}
        />

        {/* {
          url !== null ? */}
        {/* <Youtube
          videoId={getUrl(url)}
          opts={{
            height: '400px',
            width: '100%',
            //  playerVars: { autoplay: 1 } otomatik videoyu başlatır
          }} /> */}
        {/* : 
             <h5>
               Video Eklenmemiştir.
             </h5>
         } */}

      </div>
    </Modal>
  )
}

export default VideoModal
