import caller from './Caller'

export const postContact = async (model) => {
  const { data } = await caller({
    method: 'post',
    url: '/Form/Contact',
    data: {
      ...model
    }
  })
  return data
}