import caller from './Caller'

export const contactAgent = async (model) => {
  const { data } = await caller({
    method: 'POST',
    url: '/Form/ContactAgent',
    data: {
      ...model
    }
  })

  return data
}