import React from 'react'
import { Modal } from 'semantic-ui-react'

import Slider from "react-slick"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

import { AwsUrl } from '../../helpers/aws'


function NextArrow(props) {

  React.useEffect(() => {
    // document.getElementsByClassName('slick-arrow slick-next')[1].click() 
  }, [])

  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: '20px' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: '10px' }}
      onClick={onClick}
    />
  );
}

const ModalSlider = ({ isOpen, onClose, photos }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <Modal
      style={{ background: 'transparent', boxShadow: 'none' }}
      closeIcon
      open={isOpen}
      onClose={() => onClose()}

    // centered 
    >
      <div className="property-detail-silider" style={{ width: '100%', height: '70vh' }}>
        <Slider {...settings}>
          {photos?.map(item => {
            // let photoUrl = 'https://easyrent.com.mt' + item.imageCompressSource
            let photoUrl = AwsUrl + item.imageCompressSource
            return (
              <div tag="a" index={item.id} key={item.id}>
                <img className="slider-image-modal" src={photoUrl} style={{ width: '100%', height: '70vh' }} />
              </div>
            )
          })
          }
        </Slider>
      </div>
    </Modal>
  )
}

export default ModalSlider
