import React from "react";
import {
  Container,
  Grid,
  Loader,
} from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import ListItem from "./ListItem";

const List = (props) => {
  return (
    <Container style={styles().container} fluid>

      <div>
        <h1
          style={{
            fontSize: isMobile ? "1.5rem" : null,
            marginTop: 0,
            marginLeft: isMobile ? 14 : 0
          }}
        >
          Featured Properties
        </h1>
      </div>

      <Grid>
        <Grid.Row>
          <Grid.Column computer={20} mobile={16}>
            <Container fluid style={styles().items}>
              {props.propertiesLoading ? (
                <div style={{ textAlign: "center" }}>
                  <Loader active inline />
                </div>
              ) : null}
              <>
                <Grid>
                  <Grid.Row>
                    {props.properties?.map(item => (
                      <Grid.Column
                        width="16"
                        computer={3}
                        mobile={16}
                        key={"property-slink" + item.id}
                      >
                        <ListItem {...item} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </>

            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default List;

const styles = () => ({
  container: {
    paddingTop: 0,
    paddingLeft: isMobile ? 0 : 20,
    paddingRight: isMobile ? 0 : 20,
    display: 'flex',
    flex: 1,
    // alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: "column"
  },
  listHeader: {
    margin: 0,
    padding: isMobile ? 0 : 5,
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1.5px solid #ccc",
    width: "100%",
  },
  items: {
    marginTop: isMobile ? 15 : 30,
  },
  iconButton: {
    backgroundColor: "#353a47",
    margin: 5
  },
  button: {
    backgroundColor: "#353a47",
    margin: 5
  },
  referenceNumber: {
    backgroundColor: "#fff"
  },
});
