import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";
import {
  Card,
  Container,
  Grid,
  Icon,
  Image,
  Label,
  Pagination,
} from "semantic-ui-react";
import api from "../../api";
import { AwsUrlBlog } from "../../helpers/aws";

function Blog() {
  const [state, setState] = React.useState({
    posts: [],
    pageIndex: 0,
    totalPage: 1,
  });

  const load = async (page) => {
    const res = await api.blog.getBlogPosts(page - 1);
    if (res && res.result) {
      let totalPage = Math.ceil(res.result.total / 20);
      setState({
        posts: res.result.data,
        pageIndex: page,
        totalPage: totalPage > 0 ? totalPage : 1,
      });
    }
  };

  React.useEffect(() => {
    load(1);
  }, []);

  const renderItem = (item) => (
    <Card style={styles.card} href={"/blog/" + item.slug} fluid>
      <Image
        style={styles.image}
        src={AwsUrlBlog + item.imageURLThumbnail}
        onError={(i) => (i.target.src = "/favicon.ico")}
      />
      <Card.Content>
        <Card.Header>{item.title}</Card.Header>
        <Card.Meta></Card.Meta>
        <Card.Description>
          {item.tags?.split(",")?.map((tag, _) => (
            <Label
              as="a"
              tag
              color="green"
              key={"tag-" + _}
              style={{ marginBottom: 2 }}
            >
              {tag}
            </Label>
          ))}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name="calendar" />
        {moment(item.publishedDate).format("DD.MM.YYYY")}
      </Card.Content>
    </Card>
  );

  return (
    <Container style={styles.container} fluid>
      <Grid container columns={isMobile ? 1 : 4} centered={isMobile}>
        {state.posts.map((item, _) => (
          <Grid.Column key={"post-" + _}>{renderItem(item)}</Grid.Column>
        ))}
      </Grid>
      <div style={{ height: 50 }}></div>
      <Container>
        <Pagination
          defaultActivePage={1}
          totalPages={state.totalPage}
          activePage={state.pageIndex}
          onPageChange={(props, data) => {
            console.log("onPageChanges: ", props, data);
            load(data.activePage);
          }}
        />
      </Container>
    </Container>
  );
}

export default Blog;

const styles = {
  container: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    minHeight: "80vh",
  },
  card: {
    height: "100%",
  },
  image: {
    height: "20vh",
    "object-fit": "cover",
  },
};
