import caller from "./Caller";

export const getProperties = async (page, filter = {}, lettingType) => {
  if (filter.squareOfMetersMin === null || filter.squareOfMetersMin === "") {
    filter.squareOfMetersMin = 0;
  }
  if (filter.squareOfMetersMax === null || filter.squareOfMetersMax === "") {
    filter.squareOfMetersMax = 0;
  }
  const { data } = await caller({
    method: "POST",
    url: "properties",
    data: {
      page: page,
      ...filter,
      lettingType: lettingType,
    },
  });

  return data;
};

export const getFilterOptions = async () => {
  const { data } = await caller({
    method: "GET",
    url: "/Properties/FilterOptions",
  });

  return data;
};

export const getPropertiesDetail = async (propertyId, lettingType) => {
  console.log("get", propertyId);
  const { data } = await caller({
    method: "GET",
    url: "/Properties/" + propertyId,
    params: {
      lettingType: lettingType,
    },
  });
  return data;
};

export const getCategoryBySlug = async (slug) => {
  const { data } = await caller({
    method: "GET",
    url: "/Categories/GetCategoryWithUrlSlug",
    params: {
      urlSlug: slug,
    },
  });
  return data;
};


export const getSlink = async (id) => {
  const { data } = await caller({
    method: "POST",
    url: "/Properties/GetFeaturedProperties",
    params: {
      id,
    },
  });

  return data;
};
