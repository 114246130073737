import React, { useEffect } from "react";
import {
  Container,
} from "semantic-ui-react";
import List from "./List";
import api from "../../api";

function SLink() {

  const slug = document.location.pathname.substring(1).split("/")[1];
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const res = await api.properties.getSlink(slug);
      if (res && res.result) {
        setData(res.result.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }

  };

  return (
    <Container style={styles.container} fluid>
      <List
        propertiesLoading={loading}
        properties={data}
      />
    </Container>
  );
}

export default SLink;

const styles = {
  container: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    minHeight: "80vh",
  },
  card: {
    height: "100%",
  },
  image: {
    height: "20vh",
    "object-fit": "cover",
  },
};
