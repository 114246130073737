import React from 'react'
import {
  Button, Container, Grid, Image, Placeholder
} from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet";


function PopularCities({ }) {

  const URL = 'https://easyrent.s3.eu-central-1.amazonaws.com/ProjectFiles/'

  const history = useHistory()

  function getImage(index) {

    return URL + index
  }

  function getLabel(index) {
    return index
  }
  const onClick = (id) => {

    var urlGo;

    if (id===10)
    {
      urlGo = '/property-for-sale-in-sliema'
    }

    if (id===11)
    {
      urlGo = '/property-for-rent-in-st-julians'
    }

    if (id===5)
    {
      urlGo = '/property-for-rent-in-bugibba'
    }

    if (id===12)
    {
      urlGo = '/property-for-rent-in-gzira'
    }

    let form = { }
    history.push({
      pathname: urlGo,
      state: { form: form }
    })
  }

  const container = () => (
    <div>
      {/* <link rel="preload" as="image" href={URL + 'm4.jpg'} /> */}
      <Grid>
        <Grid.Column computer={10} mobile={16} onClick={() => onClick(10)}> 
          <img alt="Sliea" rounded src={getImage('m4.jpg')} style={styles.image} />
          <div className="pc-info">
            <Button circular>{getLabel('Sliema')}</Button>
          </div>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} onClick={() => onClick(11)}>
          <Image alt="St Julians" rounded src={getImage('m3.jpg')} style={styles.image} />
          <div className="pc-info">
            <Button circular>{getLabel('St Julians')}</Button>
          </div>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column computer={6} mobile={16} onClick={() => onClick(5)}>
          <Image alt="Bugibba" rounded src={getImage('m2.jpg')} style={styles.image} />
          <div className="pc-info">
            <Button circular>{getLabel('Bugibba')}</Button>
          </div>
        </Grid.Column>
        <Grid.Column computer={10} mobile={16} onClick={() => onClick(12)}>
          <Image alt="Gzira" rounded src={getImage('m52.jpg')} style={styles.image} />
          <div className="pc-info">
            <Button circular>{getLabel('Gzira')}</Button>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )

  const nullContainer = () => (
    <div>
      <Grid>
        <Grid.Column computer={10} mobile={16}>
          <Placeholder fluid style={styles.image}>
            <Placeholder.Image />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <Placeholder fluid style={styles.image}>
            <Placeholder.Image />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <Placeholder fluid style={styles.image}>
            <Placeholder.Image />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={10} mobile={16}>
          <Placeholder fluid style={styles.image}>
            <Placeholder.Image />
          </Placeholder>
        </Grid.Column>
      </Grid>
    </div>
  )

  return (
    <Container style={{ textAlign: 'center', marginTop: '2vh', color: '#353a47' }}>
     
      <h1 className="pc-title">Property to Rent Malta</h1>
      {container()}
    </Container>
  )
}

export default PopularCities

const styles = {
  image: {
    width: '100%',
    height: 300, objectFit: 'cover'
  }
}