import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'

import { Media, MENU_ITEMS } from './index'

export default class MobileContainer extends Component {
  state = {
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => {
    this.setState({ sidebarOpened: true })
  }

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    //debugger

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            {
              MENU_ITEMS.map((item, i) => (
                <Link to={item.url} style={{ display: 'flex' }} key={"mobile-menu-item-" + i}>
                  <Menu.Item
                    key={i}
                    name={item.name}
                    active={this.state.active === item.name}
                    onClick={(e, data) => { this.setState({ active: data.name, sidebarOpened: false }) }}
                    link
                    style={{ alignSelf: 'center' }}
                  />
                </Link>
              ))
            }
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              vertical
              style={{ padding: 0 }}
            >

              <Menu inverted pointing secondary>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Link to="/"><Image alt="logo-white" src='/img/logo-white2.png' size="tiny" /></Link>
                </Menu.Item>
              </Menu>
            </Segment>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}