import React from "react";
import { isMobile } from "react-device-detect";
import { Container, Grid, Image } from "semantic-ui-react";

function PropertyTypes() {
  const renderGroup = (item) => (
    <div className="group-item-wrapper">
      <div className="group-item">
        <img alt="Sliea" rounded src={item.img} style={styles.image} />
        <div className="group-item-title">{item.title}</div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Grid columns={4} container>
          {item.cities.map((city, i) => (
            <Grid.Column
              className="city"
              textAlign={"left"}
              key={"city-" + city.name + "-" + i}
              href={city.link}
            >
              {city.name}
            </Grid.Column>
          ))}
        </Grid>
      </div>
    </div>
  );

  return (
    <Container
      style={{ textAlign: "center", marginTop: "2vh", color: "#353a47" }}
    >
      <h1 className="pc-title">Most Popular Property Types</h1>

      <Grid>
        <Grid.Row columns={isMobile ? 2 : 7}>
          {items.map((item, i) => (
            <Grid.Column
              style={{ "margin-bottom": "8px", color: "rgb(53, 58, 71)" }}
              href={item.link}
            >
              <Image
                src={item.img}
                as="a"
                size="medium"
                rounded
                target="_blank"
              />
              {item.name}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default PropertyTypes;

const styles = {
  image: {
    objectFit: "cover",
    borderRadius: "5px",
  },
};

const items = [
  {
    name: "Luxury Villas For Rent in Malta",
    link: "https://easyrent.com.mt/luxury-villas-for-rent-in-malta",
    img: "/img/home/luxury-villa.jpeg",
  },
  {
    name: "Townhouses For Rent in Malta",
    link: "https://easyrent.com.mt/townhouses-for-rent-in-malta",
    img: "/img/home/townhouse.jpeg",
  },
  {
    name: "Penthouses For Rent in Malta",
    link: "https://easyrent.com.mt/penthouses-for-rent-in-malta",
    img: "/img/home/penthouse.jpeg",
  },
  {
    name: "Maisonettes For Rent in Malta",
    link: "https://easyrent.com.mt/maisonettes-for-rent-in-malta",
    img: "/img/home/maisonettes.jpeg",
  },
  {
    name: "Apartments For Rent in Malta",
    link: "https://easyrent.com.mt/apartments-for-rent-in-malta",
    img: "/img/home/apartments.jpeg",
  },
  {
    name: "Farmhouses For Rent in Malta",
    link: "https://easyrent.com.mt/farmhouses-for-rent-in-malta",
    img: "/img/home/farmhouse.jpeg",
  },
  {
    name: "Terraced Houses For Rent in Malta",
    link: "https://easyrent.com.mt/terraced-houses-for-rent-in-malta",
    img: "/img/home/terraced.jpeg",
  },
  {
    name: "Houses of Character For Rent in Malta",
    link: "https://easyrent.com.mt/houses-of-character-for-rent-in-malta",
    img: "/img/home/houses-of-character.jpeg",
  },
  {
    name: "Seafront Property For Rent in Malta",
    link: "https://easyrent.com.mt/seafront-property-for-rent-in-malta",
    img: "/img/home/seafront.jpeg",
  },
  {
    name: "Bars for Rent in Malta",
    link: "https://easyrent.com.mt/bars-for-rent-in-malta",
    img: "/img/home/bars.jpeg",
  },
  {
    name: "Offices for Rent in Malta",
    link: "https://easyrent.com.mt/offices-for-rent-in-malta",
    img: "/img/home/office.jpeg",
  },
  {
    name: "Garage For Rent in Malta",
    link: "https://easyrent.com.mt/garage-for-rent-in-malta",
    img: "/img/home/garrage.jpeg",
  },
  {
    name: "Hotels For Rent in Malta",
    link: "https://easyrent.com.mt/hotels-for-rent-in-malta",
    img: "/img/home/hotels.jpeg",
  },
  {
    name: "Restaurant For Rent in Malta",
    link: "https://easyrent.com.mt/restaurant-for-rent-in-malta",
    img: "/img/home/restaurants.jpeg",
  },
];
