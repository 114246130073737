import React from 'react'
import { Modal } from 'semantic-ui-react'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ text }) => (
  <div className="locatiom-modal">
    <img src="https://cdn0.iconfinder.com/data/icons/20-flat-icons/128/location-pointer.png" style={{ height: 30 }} />
  </div>
);

export default class ShowLocationModal extends React.Component {

  static defaultProps = {
    center: {
      lat: 35.911292,
      lng: 14.489585
    },
    zoom: 14
  };

  render() {
    return (
      <Modal
        style={{ width: "70%", height: '500px' }}
        closeIcon
        size={"mini"}
        open={this.props.isOpen}
        onClose={() => this.props.onClose()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {/* <iframe title="location-iframe" style={{width:'100%',height:'100%'}}
         src={`https://maps.google.com/maps?q=${this.props.lat},${this.props.lng}&z=15&output=embed`}>
        </iframe> */}
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCny9n4aoP6a6csRPxw-CsZq3ly5hJhBZU' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <AnyReactComponent
              lat={this.props.lat}
              lng={this.props.lng}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </Modal>
    )
  }
}
