import * as properties from "./Properties";
import * as contactAgent from "./ContactAgent";
import * as requestFlatMate from "./RequestFlatMate";
import * as requestViewing from "./RequestViewing";
import * as home from "./home";
import * as careers from "./careers";
import * as contact from "./contact";
import * as searchByCoordinate from "./searchByCoordinate";
import * as requestPropertyAdd from "./RequestPropertyAdd";
import * as blog from "./blog";

export default {
  properties,
  contactAgent,
  requestFlatMate,
  requestViewing,
  home,
  careers,
  contact,
  searchByCoordinate,
  requestPropertyAdd,
  blog,
};
