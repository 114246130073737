import React from 'react'
import { Grid, Container, Icon, Breadcrumb, Form, Message } from 'semantic-ui-react'

import { Helmet } from 'react-helmet'

const Company = () => {

  React.useEffect(() => {
    const script = document.createElement("script")
    script.async = true

    script.type = "application/ld+json"

    const codeStr = `
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": "Easy Rent",
    "image": "https://www.easyrent.com.mt/img/logo-green.png",
    "@id": "",
    "url": "https://www.easyrent.com.mt/",
    "telephone": "99998330",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "420 Triq D'Argens",
      "addressLocality": "Gżira",
      "postalCode": "",
      "addressCountry": "MT"
    } ,
    "sameAs": [
      "https://www.facebook.com/easyrentmt/",
      "https://www.instagram.com/easyrent.malta/",
      "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
      "https://www.linkedin.com/company/easyrent-malta/"
    ] 
`
    script.innerHTML = codeStr

    document.body.appendChild(script);
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container style={{ paddingTop: '2rem' }}>

      <Helmet>
        <title>Property to Rent Malta | Real Estate Agent in Malta</title>
        <meta name="description" content="Long Lets and Short Lets Apartments in Malta. Easy Rent Malta – One of the best Real Estate agent in Malta. The easiest way to find your ideal property in Malta." />
        <meta name="keywords" content="apartment for rent, rent in malta, real estate in malta, accommodation in malta, long lets apartments, short lets apartments, commercial property, property for sale" />

      </Helmet>

      <Breadcrumb>
        <Breadcrumb.Section link href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Company</Breadcrumb.Section>
      </Breadcrumb>

      <Grid style={{ paddingTop: '5rem', paddingBottom: '10rem' }}>
        <Grid.Column computer={10} mobile={16}>
          <h1>COMPANY</h1>
          <p>EasyRent is very young but already well known real estate agency among customers who prefers novelty and efficiency.</p>
          <p>Easyrent office is located in the center of Malta, Rue D'Argens Gzira. Here you can meet independent agents, many of whom speak multiple languages (spanish, french, russian, turkish, arabic, lithuanian and etc) and who are poised to deliver exceptional real estate services that exceed customer expectations.
          </p>
          <p>Furthermore to begin and end your real estate search visit easyrent.com.mt - the one of the fastest and most innovative websites in Malta which is an indispensable resource designed with you in mind.
          </p>
          <p>The website is not only super fast but it is also the only real estate page in Malta which has an up to date map view which makes the searching process much easier.</p>
          <p>For your convenience we have two branches, one in San Giljan and second one in Gzira.</p>
          <p> Contact us now and make your new home searching experience smooth and enjoyful! </p>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <h1>ADDRESS</h1>
          <br />
          <Icon name="map pin" /> 420 Triq D'Argens, Il-Gżira
          <br /><br />
          <Icon name="envelope open" /> <a href="mailto:info@easyrent.com.mt">info@easyrent.com.mt</a>
          <br /><br />
          <Icon name="phone" /> <a href="tel:99998330">99998330</a>
          <br /><br />
          <a href="https://www.facebook.com/easyrentmt/" target="_blank"><Icon name="facebook" /></a>
          <a href="https://www.instagram.com/easyrent.malta" target="_blank"><Icon name="instagram" /></a>
          <br /><br />
          <iframe title="title0"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12926.32340799068!2d14.494368!3d35.90828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e4547e11df0ed%3A0xf2230dfc44f4f60a!2s420%20Triq%20D&#39;Argens%2C%20Il-G%C5%BCira!5e0!3m2!1sen!2smt!4v1611011889212!5m2!1sen!2smt" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0">
          </iframe>
        </Grid.Column>
      </Grid>
    </Container>
    
  )
}
export default Company
