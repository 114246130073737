import caller from './Caller'

export const getCareers = async () => {
  const { data } = await caller({
    method: 'GET',
    url: '/Careers'
  })
  return data
}

export const postCareers = async (model) => {
  const { data } = await caller({
    method: 'POST',
    url: '/Careers',
    data: model,
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  return data
}