import React from "react";
import { isMobile } from "react-device-detect";
import { Container, Grid, Image } from "semantic-ui-react";

function MostPopularPropertyTypes({ mostPopularPropTypes }) {
  const renderGroup = (item) => (
    <div className="group-item-wrapper">
      <div className="group-item">
        <img alt="Sliea" rounded src={item.mostPopularPropertyType.image} style={styles.image} />
        <div className="group-item-title">{item.mostPopularPropertyType.title}</div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Grid columns={4} container>
          {item.cities.map((city, i) => (
            <Grid.Column
              className="city"
              textAlign={"left"}
              key={"city-" + city.name + "-" + i}
              href={city.link}
            >
              {city.name}
            </Grid.Column>
          ))}
        </Grid>
      </div>
    </div>
  );

  return (
    <div style={{ textAlign: "left", marginTop: "2vh", color: "#353a47" }}>
      <h1 className="pc-title"> Most Popular Property Types</h1>
      <Grid>
        <Grid.Row columns={isMobile ? 2 : 7}>
          {mostPopularPropTypes.map((item, i) => (
            <Grid.Column
              style={{ "margin-bottom": "8px", color: "rgb(53, 58, 71)" }}
              href={item.mostPopularPropertyType.url}
            >
              <Image
                src={item.mostPopularPropertyType.image}
                as="a"
                size="medium"
                rounded
                target="_blank"
              />
              {item.mostPopularPropertyType.title}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default MostPopularPropertyTypes;

const styles = {
  image: {
    objectFit: "cover",
    borderRadius: "5px",
  },
};
